import Vue from 'vue';
import VueRouter from 'vue-router';
import { getSessionStorage } from "utils/cookie";

const Login = () => import('views/Login.vue');

Vue.use(VueRouter);

const routes = [
  {
    path: '*',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/diagnosis-login',
    name: 'DiagnosisLogin',
    component: () => import('views/DiagnosisLogin/Index.vue')
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

// 注册全局钩子拦截导航
router.beforeEach((to, from, next) => {
  const user = getSessionStorage('userid');
  // 判断要去的路由是否含有requiresAuth属性
  if (to.meta.requiresAuth) {
    if (user) {
      next();
    } else {
      next({
        path: '/login',
        query: { redirect: to.fullPath }// 将跳转的路由path作为参数，登录成功后跳转到该路由
      });
    }
  } else {
    next();
  }
});

export default router;
