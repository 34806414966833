import request from "@/utils/request";

export default async function (url, data, config, option) {
  const res = await request({
    url,
    method: data ? "post" : "get",
    data,
    ...config
  }, option);
  return res.data;
}
