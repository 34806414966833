<!--  -->
<template>
  <div class="toast">
    <div
      ref="item"
      class="message-item"
      :class="item.type"
      v-for="(item, index) in messageList"
      :key="index"
    >
      <img :src="require(`../../assets/img/${item.type}.png`)" alt="" />
      <span class="content">{{ item.message }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      messageList: []
    };
  },
  methods: {
    showMessage(message, type = 'normal', time = 3000) {
      this.messageList.push({
        message,
        type
      });
      this.$nextTick(() => {
        const nodes = this.$refs.item;
        const node = nodes[nodes.length - 1];
        const height = nodes.length * 100;
        console.log(nodes, node, height);
        setTimeout(() => {
          console.log(time);
          node.style.transition = '500ms all linear';
          node.style.transform = `translateY(-${height}px)`;
          node.style.marginTop = '0';
          node.style.opacity = '0';
          setTimeout(() => {
            this.messageList.pop();
          }, nodes.length * 1000);
        }, time);
      });
    }
  }
};
</script>

<style>
.toast {
  top: 100px;
  transform: translateX(-50%);
  position: fixed;
  left: 50%;
}

.message-item {
  margin-top: 15px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 40px;
  border-radius: 6px;
  animation: fade .3s;
}

.content {
  margin-left: 30px;
}

.message-item img {
  height: 15px;
  width: 15px;
}

.normal {
  background-color: #edf2fc;
  color: #909399;
}

.error {
  background-color: #fef0f0;
  color: #f56c6c;
}

.success {
  background-color: #f0f9eb;
  color: #67c23a;
}

.warn {
  background-color: #fdf6ec;
  color: #e6a23c;
}

@keyframes fade {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
