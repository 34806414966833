import Vue from 'vue';
import App from './App.vue';
import router from './router';
import api from "./api/common";
import toast from "./components/toast/index";


window.loginPage = 1;
try {
  if (new URL(location.href).searchParams.get("code") === "10014") {
    if (parent !== window && window.parent.loginPage) {
      window.parent.location.href = location.href;
      window.loginPage = 0;
    }
  }
} catch (e) {

}

Vue.prototype.$api = api;
Vue.config.productionTip = false;
Vue.use(toast);

window.loginPage && new Vue({
  router,
  render: h => h(App)
}).$mount('#app');
