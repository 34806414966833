import Toast from "./Toast.vue";

const obj = {};
// 当Vue.use的时候,就会调用这里的install函数,并将Vue实例作为参数传入
obj.install = function (Vue) {
  console.log("调用install函数");
  // 1.利用Vue.extend创建一个构造器
  const ToastConstructor = Vue.extend(Toast);
  // 2.实例化一个组件对象
  const toast = new ToastConstructor();
  // 3.将组件对象手动挂载$mount
  toast.$mount();
  // 4.将DOM节点添加到页面上
  document.body.appendChild(toast.$el);
  // 5.为Vue原型添加toast方法
  Vue.prototype.$toast = toast;
};

export default obj;
