import Cookies from 'js-cookie';

/**
 * 设置cookie
 * @param {String} key
 * @param {String} value
 * @param {Object} options
 * @returns
 */
export function setCookie(key, value, options) {
  Cookies.set(key, value, options);
  return value;
}

/**
 * 获取cookie
 * @param {String} key
 * @param {String} defaultValue
 * @returns
 */
export function getCookie(key, defaultValue) {
  return Cookies.get(key) || defaultValue;
}

/**
 * 删除cookie
 * @param {String} key
 */
export function removeCookie(key) {
  Cookies.remove(key);
}

/**
 * 设置本地存储
 * @param {String} key
 * @param {String} value
 */
export function setStorage(key, value) {
  localStorage.setItem(key, value);
  return value;
}

/**
 * 获取本地存储
 * @param {String} key
 * @param {String} defaultValue
 */
export function getStorage(key) {
  return localStorage.getItem(key);
}

/**
 * 删除本地存储
 * @param {String} key
 */
export function removeStorage(key) {
  localStorage.removeItem(key);
}

/**
 * 保存会话存储
 * @param {String} key
 * @param {String} value
 */
export function setSessionStorage(key, value) {
  sessionStorage.setItem(key, value);
}

/**
 * 获取会话存储
 * @param {String} key
 * @returns
 */
export function getSessionStorage(key) {
  return sessionStorage.getItem(key);
}

/**
 * 删除会话存储
 * @param {String} key
 */
export function removeSessionStroage(key) {
  sessionStorage.removeItem(key);
}
