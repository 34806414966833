import Vue from 'vue';
import axios from "axios";
import router from "../router";
import { v4 as uuidv4 } from 'uuid';

function request(config, option) {
  const opt = Object.assign({
    // 默认检测数据格式是否满足 {code: 0, msg: ''}，code存在且不为0时抛出异常
    // 如需关闭，设置 checkResponseData 为 null 即可
    checkResponseData(response) {
      return new Promise((resolve, reject) => {
        const resData = response.data;
        if (resData && (resData.code !== undefined) && (resData.code !== 0)) {
          const errMsg = resData.msg || resData.message || '请求失败';
          return reject(new Error(errMsg));
        }
        resolve(response);
      });
    },
    // 默认错误提醒，如需关闭，设置为 null 即可
    showErrorMsg(err) {
      const message = err.msg || err.message || '请求失败';
      Vue.prototype.$toast.showMessage(message, 'error', 3000);
    }
  }, option);

  // 创建axios实例
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_API || '',
    timeout: 5000
  });

  // 请求拦截器
  axiosInstance.interceptors.request.use(
    config => {
      // 在发送请求前做些什么 config.headers['X-Token'] = 'xxx'
      config.headers['requestID'] = uuidv4();
      return config;
    },
    error => {
      return Promise.reject(error);
    });

  // 响应拦截器
  axiosInstance.interceptors.response.use(
    // http状态码 = 200情况
    response => {
      if (opt.checkResponseData) {
        return opt.checkResponseData(response).then(() => {
          return Promise.resolve(response);
        }, (err) => {
          opt.showErrorMsg && opt.showErrorMsg(err);
          return Promise.reject(err);
        });
      }
      return Promise.resolve(response);
    },
    // http状态码 != 200情况
    error => {
      const resp = error.response || {};
      // 401 清除token信息并跳转到登录页
      if (resp.status === 401) {
        Promise.reject(error);
        // 当前路由不是登录页才进行跳转
        router.currentRoute.path !== 'login' && router.replace({
          path: 'login',
          query: { redirect: router.currentRoute.path }
        });
      } else {
        // 如果遇到其他错误响应状态码,如500,进行全局错误提示
        opt.showErrorMsg && opt.showErrorMsg(resp.data);
      }
      return Promise.reject(error);
    }
  );
  return axiosInstance.request(config);
}

export default request;
